import React, { useEffect, useState } from "react";
import "./App.scss";
import Bars from "./components/Bars/Bars.js";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./containers/Home/Home.js";
import Idemo from "./containers/Idemo/Idemo";
import Kalendar from "./containers/Kalendar/Kalendar";
import Manastiri from "./containers/Manastiri/Manastiri";
import Ikone from "./containers/Ikone/Ikone";
import SinglePost from "./containers/SinglPost/SinglePost";
import SinglePost2 from "./containers/SinglPost/SinglePost2";
import ScrollToTop from "./UI/ScrollToTop/ScrollToTop";
import HeadHelmet from "./components/HeadHelmet/HeadHelmet";
import { LocationProvider } from "./shared/LocationContext";
import withIdProvider from "./shared/HOC";

export default function App() {
  const [data, setData] = useState(null);
  useEffect(() => {
    fetch("http://localhost:3001/data.json")
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.log("Error fetching data:", error));
  }, []);
  return (
    <Router>
      <LocationProvider>
        <HeadHelmet />
        <ScrollToTop />
        <div className="app">
          <Bars />
          <div className="app-wrapper">
            <Routes>
              <Route
                path="/"
                exact="true"
                element={React.createElement(withIdProvider(Home))}
              />
              <Route
                path="/ikone"
                exact="true"
                element={React.createElement(withIdProvider(Ikone))}
              />
              <Route
                path="/crkveni-kalendar"
                exact="true"
                element={React.createElement(withIdProvider(Kalendar))}
              />
              <Route
                path="/crkveni-kalendar/:slug/:id"
                exact="true"
                element={React.createElement(withIdProvider(Kalendar))}
              />
              <Route
                path="/ostalo/:id"
                exact="true"
                element={React.createElement(withIdProvider(SinglePost2))}
              />
              <Route
                path="/manastiri"
                exact="true"
                element={React.createElement(withIdProvider(Manastiri))}
              />
              <Route
                path="/manastirske-price/:id"
                exact="true"
                element={React.createElement(withIdProvider(SinglePost2))}
              />
              <Route
                path="/slave/:id"
                exact="true"
                element={React.createElement(withIdProvider(SinglePost2))}
              />
              <Route
                path="/manastiri/:id/:id"
                exact="true"
                element={React.createElement(withIdProvider(SinglePost))}
              />
              {/* <Route path="/manastiri/:id/:id" exact element={<Ikone />} /> */}
              {/* <Route path="/idemo" exact element={<Idemo />} /> */}
            </Routes>
          </div>
        </div>
      </LocationProvider>
    </Router>
  );
}
