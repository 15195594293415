import React, { useId } from "react";
import "./ArticleBox.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGlobalLocation } from "../../shared/LocationContext";

export default function ArticalBox(props) {
  // const navigate = useNavigate();
  // const { id } = useParams();
  // const loc = useLocation();
  const location = useGlobalLocation();
  const urlTitle = (item) => {
    const cyrilic = ["č", "ć", "ž", "š", "đ", ",", ":", "-", "?", "!"];
    const replArray = ["c", "c", "z", "s", "dj", "", "", "", "", ""];
    let regex = /--/gi;
    let d = item
      .toLowerCase()
      .split("")
      .map((x) => {
        return cyrilic.indexOf(x) === -1 ? x : replArray[cyrilic.indexOf(x)];
      });
    return d.join("").split(" ").join("-").replace(regex, "-");
  };

  const setPath = (category, title) => {
    console.log("Cat", location.pathname);
    if (location.pathname === "/manastiri") {
      // navigate(`/manastiri/${title}`);
      return "/manastiri/" + urlTitle(title);
    } else {
      return urlTitle(category) + "/" + urlTitle(title);
    }
  };

  const news = props.data;
  const { category, title } = props.data;
  const classes = props.classes;

  return (
    <Link to={setPath(category, title)} className={classes}>
      <div className="test11">
        <img src={news.pics[0]} alt={news.tags[0]} />
        <div className="test12">
          <h2>{news.title}</h2>
          <h3 className="test112">
            {news.category} <span>| {news.time}</span>
          </h3>
        </div>
      </div>
    </Link>
  );
}
