import React, { Component } from "react";
import "./ContentEx.scss";
import news from "../../all__news.json";

export default class ContentEx extends Component {
  state = {
    loaded: false,
    error: false,
    fallBackSrc: "/img/manastir.jpg",
  };
  urlTitle = (a) => {
    const cyrilic = ["č", "ć", "ž", "š", "đ", ",", ":", "-", "?", "!"];
    const replArray = ["c", "c", "z", "s", "dj", "", "", "", "", ""];
    let regex = /--/gi;
    let d = news[a].title
      .toLowerCase()
      .split("")
      .map((x) => {
        return cyrilic.indexOf(x) === -1 ? x : replArray[cyrilic.indexOf(x)];
      });
    return d.join("").split(" ").join("-").replace(regex, "-");
  };
  onImageError = () => {
    this.setState({ error: true });
  };
  render() {
    const articleIndex = this.props.n;
    // console.log("Data n", articleIndex);
    const redUrl = this.props.redUrl;
    // const index = this.props.index;
    const classes = this.props.classes;
    let imgSrc = !this.state.error
      ? news[articleIndex].pics[0]
      : this.state.fallBackSrc;
    return (
      <a target="_blank" className={classes} href={news[articleIndex].redUrl}>
        <div className="test11">
          <img src={imgSrc} alt="" onError={this.onImageError} />
          <div className="test12">
            <h2>{news[articleIndex].title}</h2>
            <h3 className="test112">
              {news[articleIndex].category}{" "}
              <span>| {news[articleIndex].time}</span>
            </h3>
          </div>
        </div>
      </a>
    );
  }
}
