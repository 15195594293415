import React from "react";
import "./CalendarMonthsLinks.scss";
import { monthSerb } from "../Calendar/calendar-data/calendar-data";
import { NavLink } from "react-router-dom";
import { useIdContext } from "../../shared/IdProvider";

export default function CalendarMonthsLinks() {
  const { slug, currentYear } = useIdContext();
  return (
    <table className="calendar-allMonths">
      <tbody>
        <tr>
          {monthSerb.slice(0, 4).map((item, index) => {
            return (
              <td key={index}>
                <NavLink
                  to={`/crkveni-kalendar/${slug || currentYear}/${item}`}
                >
                  {item}
                </NavLink>
              </td>
            );
          })}
        </tr>
        <tr>
          {monthSerb.slice(4, 8).map((item, index) => {
            return (
              <td key={index}>
                <NavLink
                  to={`/crkveni-kalendar/${slug || currentYear}/${item}`}
                >
                  {item}
                </NavLink>
              </td>
            );
          })}
        </tr>
        <tr>
          {monthSerb.slice(8, 12).map((item, index) => {
            return (
              <td key={index}>
                <NavLink
                  to={`/crkveni-kalendar/${slug || currentYear}/${item}`}
                >
                  {item}
                </NavLink>
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
}
