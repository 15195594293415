import React from "react";
import "./Ikone.scss";
import AdManagerSlot from "../../components/AdvModule/AdManagerSlot";
import { useGlobalLocation } from "../../shared/LocationContext";

export default function Ikone() {
  const { location } = useGlobalLocation();
  return (
    <div className="ikone">
      <div className="banner-wrapper">
        <AdManagerSlot
          adUnitPath={location.pathname}
          slotNumber={"div-gpt-ad-1723658374440-0"}
        />
      </div>
      <div className="ikone-wrapper">
        <div className="ikone-text">
          <p>
            Ikonopis (ili ikonopisanje) je posebna slikarska tehnika koju neguje
            hrišćanska crkva (naročito Pravoslavna). U Pravoslavnoj Crkvi
            naročito je raširen i razvijen kult ikona, i mada ikone spadaju u
            kult i drugih konfesija, pravoslavlje je najfinije i najdetaljnije
            razvilo ikonopis tako da sva pravila i načini ikonopisa, dole
            napisana, karakteristična su za Pravoslavnu Crkvu. U drugim
            konfesijama koje poštuju ikonu ova pravila ne moraju biti poštovana
            (primer je Rimokatolička crkva u kojoj nije detaljno preciziran i
            dogmatski određen način slikanja ikona).
          </p>
          <AdManagerSlot
            adUnitPath={location.pathname}
            slotNumber={"div-gpt-ad-1724672473185-0"}
          />
          <p>
            U Pravoslavnoj Crkvi postoje određeni kanoni (pravila) ikonopisanja
            kojih se pridržava svaki ikonopisac. Pravila nisu zvanično propisana
            već se prenose generacijama sa majstora na učenika. Osnovno nepisano
            pravilo je da se ikona radi po obrascu (predložku) neke starije
            ikone (koja je opšte prihvećeni obrazac).
          </p>
          <div className="ikone-img">
            {/* <img src="https://res.cloudinary.com/dvi29tcsc/image/upload/v1732101653/agroweb/Aran%C4%91elovdan_r9zw4d.webp" alt="Ikona"/> */}
            <figure class="mainContent-img">
              <img
                src="https://res.cloudinary.com/dvi29tcsc/image/upload/v1732101653/agroweb/Aran%C4%91elovdan_r9zw4d.webp"
                alt="Aranđelovdan"
                width="1941"
                height="1421"
              />
              <figcaption class="mainContent-img_source">
                <span>Foto: </span>
                <span>printscreen / SPC</span>
              </figcaption>
            </figure>
          </div>
          <p>
            {" "}
            Slikanje ikona naravno ne mora uvek biti po predlošku, već po opisu
            iz npr. "Ohridskog prologa" ili i samog SVETOG PISMA, ali to naravno
            zavisi od veštine, znanja i obrazovanja samog umetnika.
          </p>
        </div>
        <div className="banner-wrapper xl_sticky">
          <AdManagerSlot
            adUnitPath={location.pathname}
            slotNumber={"div-gpt-ad-1723682121612-0"}
          />
        </div>
      </div>
      <div className="banner-wrapper">
        <AdManagerSlot
          adUnitPath={location.pathname}
          slotNumber={"div-gpt-ad-1724367417806-0"}
        />
      </div>
    </div>
  );
}
