import React, { Component, lazy, Suspense } from "react";
import { Link } from "react-router-dom";
import "./SmallBox.scss";
import { tretmanColors } from "../../../shared/shared.js";
// import spisakManastira from "../../../allData.json";

export default class SmallBox extends Component {
  state = {
    loaded: false,
    error: false,
    fallBackSrc: "/img/article_img/fallBack.jpg",
  };
  onImageError = () => {
    "Change state on error";
    this.setState({ error: true });
  };
  urlTitle2 = (title) => {
    const cyrilic = ["č", "ć", "ž", "š", "đ", ",", ":", "-", "?", "!", "."];
    const replArray = ["c", "c", "z", "s", "dj", "", "", "", "", "", ""];
    let regex = /--/gi;
    let url_title = title
      .toLowerCase()
      .split("")
      .map((x) => {
        return cyrilic.indexOf(x) === -1 ? x : replArray[cyrilic.indexOf(x)];
      })
      .join("")
      .split(" ")
      .join("-")
      .replace(regex, "-");
    return url_title;
  };
  render() {
    const objekat = this.props.n;
    // console.log("Objekat", objekat);
    const articleIndex = this.props.n;
    // let imgSrc = !this.state.error ? objekat.pic[0] : this.state.fallBackSrc;
    let imgSrc = this.state.error ? this.state.fallBackSrc : objekat.pic[0];
    return (
      <Link
        to={
          this.urlTitle2(objekat.region) + "/" + this.urlTitle2(objekat.title)
        }
        className="smallBox"
      >
        <Suspense fallback={<div>Loading...</div>}>
          <div className="smallBox__img">
            <img
              src={imgSrc}
              alt=""
              onError={this.onImageError}
              loading="lazy"
            />
          </div>
        </Suspense>
        <div className="smallBox__data">
          <h2>{objekat.title}</h2>
          {/* <h3>{objekat.type}</h3> */}
          <h4>{objekat.region}</h4>
        </div>
      </Link>
    );
  }
}
const changeColor = (t) => {
  let i = tretmanColors.filter((element) => {
    return element.tretman === t;
  });
  return i[0].color;
};
