// import ReactDOM from 'react-dom'
import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { createRoot } from "react-dom/client";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
// import ScrollToTop from "./UI/ScrollToTop/ScrollToTop";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);

// ReactDOM.render(
//   <React.StrictMode>
//     {/* <ScrollToTop /> */}
//     <App />
//   </React.StrictMode>,
//   document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
serviceWorker.unregister();
